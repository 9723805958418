@import 'base';
@import 'material_ui_overides';

.MuiBox-root .app-wrapper-body {
  @media screen and (min-width: 769px) {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
  }
}
.list-view-table-table-title {
  margin-left: 0;
  padding-left: 0;
}

.datagrid-table {
  tr {
    display: flex;
  }

  td:first-child {
    flex: 1;
  }
}

/** Some fixes for styles that were were affecting the form edit modal display */
.formio-component-tabs {
  .datagrid-table {
    tr {
      display: table-row;
    }

    td,
    th {
      padding: 1px;
      vertical-align: inherit;
      &:first-child {
        flex: inherit;
      }
    }
  }
}

.btn.signature-pad-refresh {
  z-index: initial;
}

/* custom date picker*/
.datepicker-label {
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  height: var(--datepicker-size);
  min-width: 250px;
}

.datepicker-input {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: 0.2s;
  padding: 20px var(--datepicker-size) 20px 15px !important;
  border: 1px solid gray !important;
  border-radius: 0.25rem !important;
  background-image: url(../assets/date-ff.png);
  background-position: calc(100% - 15px) center;
  background-size: 20px;
  background-repeat: no-repeat;
  pointer-events: none;

  &[disabled] {
    background-color: #eef3f9;
  }

  &[readonly] {
    padding: 0px !important;
    height: auto;
    background: transparent;
    border: 1px solid transparent !important;
    color: var(--primary);
  }
}

.formio-error-wrapper .datepicker-input,
.has-error .datepicker-input {
  border: 1px solid var(--danger) !important;
}

.datepicker-input {
  &:hover:not([readonly]):not([disabled]) {
    border: 1px solid var(--primary);
    background-color: #cfe0f644;
  }

  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    left: calc(-1 * var(--datepicker-size));
    width: calc(100% + var(--datepicker-size));
    height: 100%;
    cursor: pointer;
    z-index: 1;
  }
}

/* Hide the button for legacy components */
.datepicker-button {
  pointer-events: none;
  display: none;
}

.new-meds {
  table tbody tr td {
    vertical-align: middle;
  }

  input[type='text'].form-control {
    margin-top: 0;
  }

  table thead tr th {
    width: 100%;

    &:last-of-type {
      width: 10%;
    }
  }
}

/* in view submission */
.formio-read-only .new-meds table thead tr th:last-of-type {
  width: 100%;
}

/* in form builder */
.builder-component .new-meds table thead tr th:last-of-type {
  width: 100%;
}

/* active medications table */
.active-medication {
  margin-top: 1rem;

  thead th {
    border: 0;
  }

  tbody tr td {
    vertical-align: middle;
  }

  input[type='text'].form-control {
    margin-top: 0;
  }

  .form-check.checkbox {
    margin: 0;
  }
}

/*
    Responsive table hack taken from:
    https://css-tricks.com/responsive-data-tables/
*/

.responsive-table-alternate-shading {
  tr {
    &:nth-child(even) {
      background-color: #efefef;
    }
  }
}

.responsive-table {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border-radius: 5px;
    padding: 10px;
  }

  td::before {
    font-weight: bold;
  }
}

.active-medication td,
.new-meds .table-no-border td {
  border: none;
  border-bottom: 1px solid #eee;
  position: relative;
  padding-left: 50%;
}

.new-meds .table-no-border td {
  width: 100% !important;
}

.formio-button-remove-row {
  background-color: var(--remove-cta-color) !important;
  color: white !important;
  margin: auto;

  &::before {
    padding: 0 20px;
    font-size: 1rem;
    font-weight: bold;
    content: 'DELETE';
    display: block;
  }

  & > i {
    display: none;
  }
}

.response-buttons {
  display: inline-flex;
  gap: 16px;
  margin: 30px auto;
  width: 100%;
  justify-content: center;
}

@media (max-width: 768px) {
  .active-medication thead th:first-child {
    width: 40%;
  }
}

/* extraction-component to secure extraction */
.extraction-component .component-settings-button:not(.component-settings-button-move) {
  display: none;
}

/* Column cells in forms table */
.data-qa-version {
  color: #6b7280;
}
.data-qa-version-date {
  span {
    /* centers the date spans */
    display: table;
    margin: 0 auto;
  }
  .date {
    color: #6b7280;
  }
  .time {
    white-space: nowrap;
  }
}
