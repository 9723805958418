@mixin common-floating-formio-label-styling {
  position: relative;

  label {
    display: block;
    position: absolute;
    color: #70747c;
    transform: translateY(-50%);
    left: 16px;
    transform-origin: left top;
    user-select: none;
    transition:
      transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
      top 500ms;
  }

  &.has-message label {
    top: calc(50% - 16px);
  }

  &:not(.has-message) label {
    top: 50%;
  }
}

@mixin floating-formio-labels {
  .formio-component-textfield,
  .formio-component-phoneNumber,
  .formio-component-password,
  .formio-component-number,
  .formio-component-email,
  .formio-component-url,
  .formio-component-tags,
  .formio-component-address,
  .formio-component-datetime,
  .formio-component-currency {
    input {
      height: 66px;

      &::-webkit-input-placeholder {
        opacity: 0;
      }

      &:focus::-webkit-input-placeholder {
        opacity: 1;
        transition: opacity 150ms;
      }
    }

    &:not(.formio-component-label-hidden) {
      @include common-floating-formio-label-styling;

      div.has-value + label,
      div:focus-within + label {
        top: 22px;
        font-size: 14px;
      }

      div.has-value input,
      input:focus {
        padding-top: 30px;
      }
    }
  }

  .formio-component-textarea {
    &:not(.formio-component-label-hidden) {
      @include common-floating-formio-label-styling;

      textarea {
        padding-top: 30px;
      }

      div.has-value + label,
      div:focus-within + label {
        top: 22px;
        font-size: 14px;
      }

      div.has-value textarea,
      textarea:focus {
        padding-top: 30px;
      }
    }
  }

  .formio-component-selectCustomComp,
  .formio-component-select {
    .form-control {
      padding: 0;
      height: 66px;
    }

    /*
        *  Hides the x button on the placeholder on Form.io selects with choices.js.
        *  If an option has a value of '', then the x button will be hidden automatically by choices.js.
        *  Form.io passes a value that matches the label for the placeholder option on selects,
        *  which is incorrect according to the choices.js documentation https://github.com/Choices-js/Choices#placeholder
        *  This can be removed when https://github.com/formio/formio.js/pull/4842 is merged into formio.js and released
        */
    .choices__placeholder.choices__item--selectable button {
      display: none;
    }

    &.formio-component-label-hidden .choices__list.choices__list--single {
      padding: 20px 40px 10px 15px;
      height: 64px;
    }

    &:not(.formio-component-label-hidden) {
      @include common-floating-formio-label-styling;

      .choices__list.choices__list--single {
        padding: 30px 40px 10px 15px;
        height: 64px;
      }

      div.has-value ~ label {
        top: 22px;
        font-size: 14px;
      }
    }
  }
}
