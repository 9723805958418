@import '../../scss/_base';
@import 'scss/formio-mixins';

@mixin adjust-input-padding-for-floating-labels {
  input {
    padding-left: 0.88rem;
  }
}

.formio-dialog {
  a {
    color: var(--primary);
  }

  .form-control:focus-within {
    border-color: black;
    box-shadow: none;
  }

  .preview-panel {
    @include floating-formio-labels;
    @include adjust-input-padding-for-floating-labels;
  }

  .btn {
    border-radius: 6px;
  }

  .btn-success {
    background-color: var(--primary);
    border-color: var(--primary);

    &:hover {
      background-color: var(--primary-shade);
    }

    &:active {
      background-color: var(--primary-shade) !important;
    }

    &:focus {
      box-shadow: 0 0 0 0 !important;
    }
  }

  .btn-secondary {
    background-color: #ffffff;
    border-color: #d1d5db;
    color: black;
  }

  .btn-danger {
    background-color: white;
    color: var(--danger);
    border-color: var(--danger);
  }

  .btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);

    &:hover {
      background-color: var(--primary-shade);
      border-color: var(--primary);
    }

    &:active {
      background-color: var(--primary-shade);
      border-color: var(--primary);
    }

    &:focus {
      box-shadow: 0 0 0 0;
    }
  }

  .component-edit-container {
    .btn-danger {
      float: right;
    }
  }
}

.form-builder {
  @include floating-formio-labels;
  @include adjust-input-padding-for-floating-labels;

  .form-control:focus-within {
    border-color: black;
    box-shadow: none;
  }

  .components-info {
    position: absolute;
    top: 2px;
  }

  .badge-primary,
  .btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);

    &:hover {
      background-color: var(--primary-shade);
      border-color: var(--primary);
    }

    &:active {
      background-color: var(--primary-shade);
      border-color: var(--primary);
    }

    &:focus {
      box-shadow: 0 0 0 0;
    }
  }

  .wizard-page-label {
    height: 20px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;

    &.badge-info {
      background-color: var(--primary-light);
      color: var(--primary);
    }

    &.badge-success {
      border: 1px solid #9ca3af;
      background-color: #f3f4f6;
      color: #6b7280;
    }
  }
}
