/* color scheme */
:root {
  --primary: #071f3e;
  --primary-shade: #071f3ec3;
  --primary-light: #d1e6f9;
  --secondary: #ffc803;
  --secondary-shade: #ffc803;
  --content-default: #071f3e;
  --danger: #dc3545;
  --header-size: 90px;
  --background-color: #fafafa;
  --page-visited: #134f9d;
  --slider: var(--primary);
  --progress: #00b580;
  --datepicker-size: 47px;
  --progress-radius: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --datepicker-size: 66px !important;
}
