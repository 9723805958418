// Fixes a clash between bootstrap and MUI styles on the MUI tables
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin: 0;
}

.MuiDialog-container .MuiDialog-paper {
  padding: 40px;
  width: 50vw;
}

/* Puts the border around inner mui-dataGrid and removes it from outer container so that the search and create buttons don't
  look like they are wrapped up in the forms/themes tables */
.MuiBox-root {
  .MuiDataGrid-main {
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px;
  }
  .MuiDataGrid-root {
    border: none;
    border-radius: unset;
  }

  /* Makes table headers match the figma https://www.figma.com/file/sfDZlLtWkRJ68Qu00flrA8/Forms?node-id=1416%3A2956 */
  .MuiDataGrid-columnHeaders {
    font-weight: bold;
    background-color: #f9fafb;
    color: #47525d;
    border-radius: 0;
    padding: 12px 24px 12px 12px;
  }
  .MuiDataGrid-columnHeader {
    font-family: Inter, Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    letter-spacing: 0.05em;
    text-transform: uppercase;

    /* Zus/gray/500 */
    color: #6b7280;

    &:last-child {
      /* Fix weird issue where the 3 dot menu on last column header was rendered half out the table */
      padding-right: 25px;
    }
  }
}

/* Toolbar search */
.MuiDataGrid-toolbarContainer {
  .MuiFormControl-root.MuiTextField-root {
    /* width: '40%', maxWidth: '500px', paddingBottom: '0' */
    width: 70%;
    max-width: 549px;
    padding-bottom: 0;

    .MuiInputBase-root.MuiInputBase-formControl {
      border-radius: 6px;
      /* Base/Gray/300 */
      border: 1px solid #d1d5db;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      padding: 0 0 0 13px;

      input {
        padding: 6px 13px 6px 0;
        color: #6b7280;
      }
    }
  }
}
